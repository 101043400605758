import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import HomeHeader from '../components/slices/HomeHeader'
// import WideImage from '../components/slices/WideImage'
// import HeroLead from '../components/slices/HeroLead'
import TitleBar from "../components/slices/TitleBar"
import LeadAndText from "../components/slices/LeadAndText"
import SecondaryHeader from "../components/slices/SecondaryHeader"
import PeopleCards from "../components/slices/PeopleCards"
import EditorialTextImage from "../components/slices/EditorialTextImage"
import IlustrationEditorialBanner from "../components/slices/IlustrationEditorialBanner"
import EditorialTextImageContact from "../components/slices/EditorialTextImageContact"
import ContactForm from "../components/slices/ContactForm"

// Display the title, date, and content of the Post
const PageBody = ({ page }) => {
  // console.log('[page.js] page', page)

  // const release_date = useMemo(() => (
  //   new Date(get(page, 'release_date'))
  // ), [page])

  const show_top_bar = useMemo(() => get(page, "show_top_bar", false), [page])

  const title = useMemo(() => get(page, "title[0].text"), [page])

  const renderContainer = (container, index) => {
    if (container && container.type === "text___image") {
      return <EditorialTextImage key={index} data={container} index={index} />
    }

    if (container && container.type === "secondary_header") {
      return <SecondaryHeader key={index} data={container} index={index} />
    }

    if (container && container.type === "lead___cards") {
      return <LeadAndText key={index} data={container} index={index} />
    }

    if (container && container.type === "the_team") {
      return <PeopleCards key={index} data={container} index={index} />
    }

    if (container && container.type === "illustration_banner") {
      return (
        <IlustrationEditorialBanner
          key={index}
          data={container}
          index={index}
        />
      )
    }

    if (container && container.type === "contact_us") {
      return (
        <EditorialTextImageContact key={index} data={container} index={index} />
      )
    }

    if (container && container.type === "contact_us_1") {
      return <ContactForm key={index} data={container} index={index} />
    }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        {show_top_bar === true ? <TitleBar title={title} /> : null}

        {page &&
          page?.body?.map((container, index) =>
            renderContainer(container, index)
          )}
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allPage_templates.edges[0]

  if (!doc) return null

  const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(() => get(doc, "node.seo_image.url"), [doc])

  return (
    <Layout pathname={props.path} meta={meta}>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <PageBody page={doc.node} />
    </Layout>
  )
}

export const query = graphql`
  query allPages($lang: String, $uid: String) {
    prismic {
      allPage_templates(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            seo_title
            seo_meta
            seo_image
            title
            show_top_bar
            body {
              ... on PRISMIC_Page_templateBodyText___image {
                type
                label
                primary {
                  text
                  overlay_illustration
                  image_aligment
                  image
                }
              }
              ... on PRISMIC_Page_templateBodyImages_editorial {
                type
                label
                primary {
                  image_1
                  image_2
                  image_3
                  layout
                }
              }
              ... on PRISMIC_Page_templateBodySecondary_header {
                type
                label
                primary {
                  background_image
                  sub_title
                  text
                  title1
                }
              }
              ... on PRISMIC_Page_templateBodyLead___cards {
                type
                label
                primary {
                  image
                  title1
                  color_mode
                }
                fields {
                  title1
                  text
                }
              }
              ... on PRISMIC_Page_templateBodyThe_team {
                type
                label
                primary {
                  image
                  title1
                }
                fields {
                  bio
                  name
                  photo
                }
              }
              ... on PRISMIC_Page_templateBodyIllustration_banner {
                type
                label
                primary {
                  button_text
                  button_url {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                  illustration
                  text
                  title
                }
              }
              ... on PRISMIC_Page_templateBodyContact_us {
                type
                label
                primary {
                  title1
                  right_text
                  left_text
                  image_overlay__optional_
                  image
                }
              }
              ... on PRISMIC_Page_templateBodyContact_us_1 {
                type
                label
                primary {
                  hubspot_portal_id
                  hubspot_form_id
                  form_title
                }
              }
            }
          }
        }
      }
    }
  }
`
