import React, { useMemo }from 'react'
import { get } from 'lodash'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '../../utils/linkResolver'
import HubspotForm from 'react-hubspot-form'


const ContactForm = ({ data, index  }) => {

  const title = useMemo(() => (
    get(data, 'primary.form_title[0].text')
  ), [data])

  console.log('ContactForm data', data)

  // const signupModalData = useMemo(() => (
  //   get(data, 'prismic.allSignupmodals.edges[0].node')
  // ), [data])
  
  const portalId = useMemo(() => (
    get(data, 'primary.hubspot_portal_id')
  ), [data])
  
  const formId = useMemo(() => (
    get(data, 'primary.hubspot_form_id')
  ), [data])

  return(
    <section
      key={index}
      className="contact_form">
  
      <div className="row row-justify">
        
        <div className="col col4 contact_form__left">
          <h3>{title}</h3>
        </div>

        <div className="col col6 contact_form__right">
          
          {portalId && formId ?
            <HubspotForm
              portalId={portalId}
              formId={formId}
              onSubmit={() => {}}
              onReady={(form) => {}}
            />
          :null}
          
          {/* <form>

            <div className="input_field">
              <fieldset>
                <label>First Name</label>
                <input name="full_name" type="text" />
              </fieldset>
            </div>

            <div className="input_field">
              <fieldset>
                <label>Email Address</label>
                <input name="email_address" type="email" />
              </fieldset>
            </div>

            <div className="input_field">
              <fieldset>
                <label>Message</label>
                <textarea name="message" rows="4"></textarea>
              </fieldset>
            </div>

            <button className="btn-secondary black pdp_request_sample__submit">
              Send a Message
            </button>

          </form> */}
          
        </div>
      </div>
  
    </section>
  )  
}

export default ContactForm
