import React from 'react'

const TitleBar = ({ title, index }) => {

  return(
    <section
      key={index}
      className={"title_bar"}>
        <div className="row">
          <div className="col col12">
            <h1 className="h4 title_bar__title">
              {title}
            </h1>
          </div>
        </div>
    </section>
  )  
}

export default TitleBar
